@import '@carbon/colors/scss/colors';
@import '@carbon/themes/scss/themes';

// Use the gray 10 theme
@include carbon--theme($carbon--theme--g10);

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import './_colors.scss';

body {
  font-family: 'Montserrat', sans-serif;
  background: linear-gradient(180deg, $primary 0%, $faint-grey 35%);
  background-repeat: no-repeat;
  min-height: 100vh;
}

.navbar {
  background-color: $white;
  min-height: calc(100vh - 6rem);
}

.main-wrap {
  background-color: $white;
  min-width: calc(100% - 6rem);
}
.bg-transparent {
  background-color: transparent;
}

@layer utilities {
  @variants responsive {
    .min-bh-full {
      min-height: calc(100vh - 6rem);
      max-height: calc(100vh - 6rem);
    }
  }
}

a.bx--header__menu-item {
  color: $text-02;
}
a.bx--header__name,
a.bx--header__name:hover {
  color: $text-02;
  padding: 5px 15px 5px 20px;
}
.bx--header__nav::before {
  display: none;
}
.bx--header__nav {
  padding-left: 0 !important;
}


.h-72
{
  height: auto !important;
}

.profileSubmit { 
  margin-top:10px; 
  line-height: 1.7;
}

.profileInfo
{
      top: 22%;
    position: absolute;
    float: right;
    right: 6%;
    width: 195px;
    height: 40px;
    line-height: 1.7;
    text-align: center;
    color: $white;
    background: $primary;
    font-weight: bold;
}

.tweet-text {
  border: 1px solid #B5B5B5;
}

.image-container {
  min-width: 60px;
  max-width: 60px;
  height: 60px;
}

.disable-container {
  background-color: $grey;
}
